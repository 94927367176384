<template>
  <v-container>
    <v-btn class="no-print ma-2" @click="GenerateLabels(1)">Pag.1</v-btn>
    <v-btn class="no-print ma-2" @click="GenerateLabels(2)">Pag.2</v-btn>
    <v-btn class="no-print ma-2" @click="GenerateLabels(3)">Pag.3</v-btn>
    <v-btn class="no-print ma-2" @click="GenerateLabels(4)">Pag.4</v-btn>
    <v-btn v-if="readyToPrint" class="no-print ma-2" @click="print"
      >STAMPA ETICHETTE STANZE</v-btn
    >
    <div class="hello a4355page a4355print">
      <div v-for="(label, index) in labels" :key="index" class="print_label">
        <Label
          :roomNumber="label.roomNumber"
          :qr_path="label.qr_svg_path"
        ></Label>
      </div>
    </div>
  </v-container>
</template>

<script>
import QRCode from "qrcode";
import Convert from "xml-js";

import Label from "../../components/ricognizione/RoomLabel.vue";

export default {
  name: "PrintRoomLabel",

  components: {
    Label,
  },
  props: {},

  data() {
    return {
      labels: [],
      rooms: [],

      readyToPrint: false,
    };
  },

  methods: {
    print() {
      window.print();
      //console.log(this.labels);
    },

    // Fetch rooms via API
    async fetchRooms() {
      try {
        let response = await this.$http.get(
          process.env.VUE_APP_PATRIMONIO_API + "/rooms/list",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        );
        this.rooms = response.data;
      } catch (err) {
        console.log(err);
      }
    },

    async GenerateQR(content) {
      let opts = {
        errorCorrectionLevel: "H",
        margin: 1,
        type: "svg",
      };
      
      let svg = await QRCode.toString(content, opts);
      //console.log(svg);
      let svgObj = Convert.xml2js(svg, { compact: true, spaces: 4 });

      //console.log(svgObj.svg.path[1]._attributes.d);
      let qrcode_path = svgObj.svg.path[1]._attributes.d;

      //return svg;
      return qrcode_path;
    },

    async GenerateLabels(pageNumber) {
      
      
      // It's hard to print several pages with margin using css..
      // The numnber of rooms is not going to change frequently
      // So we have fixed this piece of code to produce 4 different printable pages.
      
      // @@@@@@@@@@@@@@@@@@@@@@@ X STAMPARE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      // N.B. Ricordarsi di mettere foglio etichette nel pass tray stampante a faccia in giù
      // e lasciare margine laterale più stretto foglio etichette verso dx
      // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

      let startFrom = null;
      let lableNumber = 60;

      if (pageNumber == 1) {
        startFrom = 0;
      }
      if (pageNumber == 2) {
        startFrom = 60;
      }
      if (pageNumber == 3) {
        startFrom = 120;
      }
      if (pageNumber == 4) {
        startFrom = 180;
      }

      console.log('start from: ' + startFrom + ' to: ' + (startFrom + lableNumber));
      console.log('n. of rooms: ' + this.rooms.length);
      
      // Reset
      this.labels = [];
      this.readyToPrint = false;


      for (let r = startFrom; r < (startFrom + lableNumber); r++) {
        if (r < this.rooms.length) {
          let room = this.rooms[r];
          let qr_path = await this.GenerateQR(room.ID);

          // take last 6 char of the short uuid to show on the label
          let roomNumber = room.CODICE_STANZA;

          this.labels.push({ roomNumber: roomNumber, qr_svg_path: qr_path });
        }
      }

      this.readyToPrint = true;
    },
  },

  created: async function () {
    await this.fetchRooms();
    //console.log(this.labels);
    await this.GenerateLabels(1);
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media print {
  /* Your styles here */
  .no-print {
    display: none;
  }

  .pageBreak {
    page-break-before: always;
  }
}
@page {
  size: 210mm 297mm;
  outline: 0pt dotted;
  margin: 0 !important;
  padding-top: 0;
}

@media screen {
  .a4355page {
    width: 210mm;
    height: 290mm;
    padding-top: 10.7mm !important;
    padding-left: 4.75mm !important;
    margin: 0;
    outline: 1px solid;
  }
  .print_label {
    height: 21.2mm; /* circular label size */
    width: 38.1mm; /* circular label size */
  }
}

.a4355print {
  width: 210mm;
  height: 290mm;
  padding-top: 2mm !important;
  padding-left: 0mm !important;
}

.print_label {
  height: 22mm; /* circular label size */
  width: 39mm; /* circular label size */
}

.print_label {
  /* padding-top: 10mm !important;
  padding-left: 3mm !important; */
  margin-top: 2mm;
  margin-left: 2mm;
  outline: 0pt solid;
  /* border-radius: 0.0mm; */
  float: left;
  /* font-size: 5mm; */
}
</style>
